@media (max-width: 1024px) {
    h2 { font-size: 32px;  }
    .banner-content h1{    line-height: 40px;font-size: 30px;}
    .we-develop-content P{padding: 0px 0px ;}
    .banner-image img{width: 100%;}
    .menu-bar li { padding: 0px 10px;  }
    .our-services .section-title { text-align: center;}
    .our-services .banner-pattern .pattern_inner {  margin: 0 auto;   }
    .softdevelop{margin-top: 0;}
    .Webapp ,.Motiongraphic ,.mobdevelop , .productdesign{margin-top: 20px;}
    .offer-image{width: 300px; height: 300px;}
    .mvpbox { flex-direction: column-reverse;  }
    .productcustomised{height: auto;}
    .lefttitle .section-title, .lefttitle p, .lefttitle .mock-btn{text-align: center !important;    justify-content: center;}
    .lefttitle .banner-pattern .pattern_inner {  margin: 0 auto;  }
    .weoffer .swiper-slide:after , .weoffer:before{display: none;}
    .weoffer{padding: 50px 0px ;}
    .weoffer  .swiper-pagination{display: none;}
    .weoffer .swiper-slide{    padding: 30px 0px 90px 0px;}
    .weoffer  .swiper-button-prev,  .weoffer  .swiper-button-next{top: inherit;   bottom: 35px;}
    .weoffer .swiper-button-prev { left: 45%;  }
    .weoffer .swiper-button-next{ right: 45%;}
    .whitebg .banner-content{padding-left: 0;}
    .whitebg:before{background-color: transparent;}
    .big-pera p{font-size: 24px;    line-height: 35px;}
    }
    
    @media (max-width: 992px) {
    body{font-size: 12px;   } 
    .layout-wrapper{overflow: hidden;}
    h2 { font-size: 24px; } 
    h3 {  font-size: 18px; }
    header{padding: 10px 15px;}
    .contact-us p{text-align: center;} 
    .cta-form { padding: 30px 15px; }
    .form-control{font-size: 14px;}
     
    .logo {width: 100px; }
    .col-buttons .btn{font-size: 11px;}
    .banner-image { min-width: 100%;}
    .wedevelop-image{    padding: 20px 20px; }
    .wedevelop-image img{ max-width: 100%;  max-height: 100%;}
    .bgheight{height: auto;  min-height: auto;   padding: 50px 0px;}
    .shape1, .shape2,.shape3,.shape4,.shape5,.shape6{display: none;}    
    .banner-content h1 {line-height: 30px;font-size: 24px;margin-bottom: 10px;}
    .banner-content p{font-size: 14px;    margin-bottom: 10px;}
    .banner-content .btn {margin-top: 10px;}
    .we-develop { margin: 40px 0px;}
    .banner-pattern {margin-bottom: 15px;}
    span.and_icon {width: 40px;height: 40px;right: -30px;line-height: 40px;}
    .big-pera p{ font-size: 16px;   line-height: 24px;}
    .technology .nav-tabs .nav-link{font-size: 16px;}
    .technology-cta .tech-icon{width: 90px;height: 90px;}
    .technology-cta .tech-icon:before{left: 0; top: 0;}
    .technology-cta p { margin-bottom: 0;}
    .whitebg .swiper-slide .row{flex-direction: column-reverse;}
    .whitebg .swiper-slide .banner-content {  text-align: center; }
    .weoffer .banner-content h1{text-align: center;}
    .offer-image{margin-bottom: 30px;padding: 30px;}
    .weoffer .swiper-button-prev  { left: -10px;}
    .weoffer .swiper-button-next {right: -10px;}
    .weoffer .swiper-slide{ padding: 20px 0px 20px 0px;}
    .weoffer  .swiper-button-prev, .weoffer  .swiper-button-next{ top: 45%;}
    .service-content h3{margin-bottom: 15px;    font-size: 16px;}
    .work-cta{padding: 10px 15px;}
    .work-circal {width: auto;height: auto;}
    .productcustomised .section-title {margin-bottom: 15px;}
    .container{max-width: 100%;}
    .bars{display: block;}
    .bars {width: 25px;line-height: 10px;cursor: pointer;    display: flex;flex-direction: column;gap: 6px;}
    .bars span {background: #409bdf;height: 3px;width: 100%;display: inline-block;transition: 0.25s ease-in-out;}
    .banner-content .bookdemobtn .btn{font-size: 12px;}
    .bars span.top {top: 0%;}
    .bars span.middle {width: 70%;}
    .bars span.bottom {bottom: 0;}
    .logo-box {display: flex;align-items: center;gap: 30px; }
    header {border-bottom: 1px solid #eee;    padding: 10px 0px !important;}
     
    .overlay3 {height: 100%;width: 0;position: fixed;z-index: 99;top: 0;left: 0;background-color: rgba(0,0,0, 0.2);overflow-y: auto;overflow-x: hidden;text-align: center;opacity: 0;transition: opacity 1s;}
    .menu-bar .navbar-nav{width: 100%;padding: 15px 0px;}
    .sidebarhead {display: flex;align-items: center;justify-content: space-between;padding: 13px 15px;border-bottom: 1px solid #eee;}
    .logonav{width: 145px;}
    .logonav img{width: 100%;}
    .sideclose {font-size: 16px;cursor: pointer;color: #03a9f4;font-weight: bold;width: 30px;height: 30px;border: 1px solid #eee;display: flex;align-items: center;justify-content: center;border-radius: 50%;}
    .menu-bar li a{padding-left: 15px !important;padding: 10px 0px;}
    .ng-reflectors{font-size: 18vw;}
    .mob-none{display: none;}
    .wedevelop-image{    height: 283px;}
    .Webapp, .Motiongraphic, .softdevelop, .mobdevelop, .productdesign {
        margin-top: 15px;
        height: auto;
    }
    .auth-bg{display: none;}
    .book-demo .bookdemoauth-full-page-content{    padding: 50px 25px 50px 25px;}
    
    .show_hide{display: block;}
     
    .ats_content_wrapper{width: 100%;}
    .top_analysis_box{padding: 15px;}
    .resume_top_title {
        margin-bottom: 15px;
        margin-top: 0px;
    }
    .ats_flex{display: inherit;}
    
    .score_aside {
        width: 0;
        flex-shrink: 0;
        position: fixed !important;
        top: 0;
        z-index: 999;
        height: 100%;
       
        padding-right: 10px;
        background: #fff;
        left: -300px;
        transition: 0.3s all ease 0s; 
    
        
    }
    .score_aside.show_issue{width: 85%; left: 0px;} 
    .score_box {
        max-height: calc(100vh - 55px);
        overflow: auto;
        position: initial;
        top: auto;
        padding: 15px;
    }
    .close_sidebar {
        text-align: right;
        padding: 10px 30px;
        background: #fafafa;
    }
    .learning_growth_box{margin-bottom: 20px;}
     
    .section_head{    padding: 0px 0px 0px 0px;}
    .section_body{    padding: 15px 0px 15px 0px;}
    .mobile_show_resume {
        position: fixed;
        bottom: 25px;
        text-align: center;
        left: 0;
        right: inherit;
        margin: 0 auto;
        width: 100%;
        z-index: 999;
    }
    .mobile_analysis_menu svg{font-size: 26px;}
    
    .accordion_meassage h4 {
        font-size: 15px;
        line-height: 27px;
    }
    .section_title h2 {
        font-size: 16px;}
    
        .mobile_show_resume .btn {
            box-shadow: 0px 0px 20px 12px #0000001a !important;
        width: 50%;
        font-size: 16px;
        padding: 10px 15px;  
        }
    
    .analysis_content{padding-bottom: 50px;}
    .viewer-flex{flex-direction: column;}
    .resume_wrapper{width: 0%; transition: 0.3s all ease 0s;  }
    
    .resume_wrapper.show_issue_resume {
        /* width: 0%; */
        position: fixed;
        z-index: 9999;
        width: 100%;
        height: 100%;
        transition: 0.3s all ease 0s; 
    }
    .faq_accordion .MuiAccordionDetails-root {
        padding: 0px 0px 0px;
        font-size: 14px;
        line-height: 25px;
    }
    .resume_wrapper .viewer-head{justify-content: space-between;padding: 15px;}
    .response_type ul{padding-left: 0;}
    .resume_wrapper  .custom-body {
        padding: 5px 15px 25px 15px;
    }
    }
    
    @media (max-width: 767px) {
    .container{width: 100%; max-width: 100%;}
    .banner-image img { object-fit: contain;  }
    .weoffer .swiper-button-next { right: 0;  }
    .weoffer .swiper-button-prev { left: 0px;  }
    .technology .nav-tabs .nav-link.active:before, .technology .nav-tabs .nav-link:hover:before{bottom: -20px;border: 9px solid transparent;border-top-color: #f9d53e;}
    .value .services-cta{margin-bottom: 20px; height: auto;}
    .ourvisionmission .section-title {text-align: center;}
    .ourvisionmission{text-align: center;}
    .ourvisionmission .pattern_inner {margin: 0 auto;}
    .ourvision{margin-top: 50px;} 
    .mission-image{    width: 300px;height: 300px;padding: 40px;margin: 25px auto !important; }
    .ourvision::before {top: 0;}
    .ng-reflectors{font-size: 17vw;}
    .comon-inner-banner h2 {font-size: 28px;line-height: 38px;}
    .plan-title{height: auto;}
    .minibanner{padding: 50px 0px 50px 0px;}
    .comon-inner-banner p {
        font-size: 15px;
    }
    .plan-duration{margin-top: 30px;}
    .plan-tables-container{flex-direction: column;gap: 15px;}
    .addons-box {
     
        padding: 30px 15px;
    }
    .add-ons-plans {
      
        grid-template-columns:  1fr;
     
    }
    .add-ons-row{flex-direction: inherit;}
    .left-value-image , .right-value-image {margin-bottom: 30px;}
    .value {margin: 30px 0px;}
    .mg-box { margin-top: 30px;}
    .minibanner{height: auto;}
    .ourvisionmission:before{    border: 40px solid #d7e9ff;     opacity: 0.2;}
    .whoweare{padding: 50px 0px;}
    .whoweare-content p {font-size: 14px;   line-height: 30px;}
    .whoweare-content{margin-top: 0;}
    .whoweare:after{top: 3%;}
    .careericon {width: 160px;height: 160px;}
    .jobcta:before{width: 250px;height: 250px;    top: -60px;left: -65px; }
    .bottom-row{    margin-bottom: 15px;}
    .skill-box p{font-size: 16px;}
    .openinglocation {font-size: 15px;}
    .job-title {margin: 15px 0px 5px 0px;}
    .jobcta{height: 100%;}
    .common-detail {padding: 30px 15px 0px 15px;}
    .common-detail h3 {font-size: 18px;margin-bottom: 7px;margin-top: 25px;}
    .common-detail ul{padding-left: 15px;}
    .common-detail  ul li{font-size: 14px;    padding: 5px 0px;}
    .carrer-detail-banner{padding: 100px 0px 30px 0px !important;}
    .jobapplycard .form-group label {margin-bottom: 7px;font-weight: bold;font-size: 15px;}
    .apply   .form-group{margin-bottom: 10px;}
    .mob-none{display: block;}
     
    .login-box{height: auto;    padding: 45px 20px; margin-bottom: 0;}
    .Ph-login-register {
        overflow: inherit;
        height: auto;
        max-height: initial;
       
    }
    .our-services .icon-circal {
        padding: 20px;
        width: 125px;
        height: 125px;
       
    }
    
    .btn{    padding: 6px 10px;}
    .icon-circal{width: 155px;
        height: 155px;    padding: 25px; margin: 0 auto;}
        .mock-btn .btn{flex: 1;}
        .copyright p{font-size: 13px;}
    
        .work-cta{height: auto; margin-bottom: 20px;}
        .wedevelop-image{height: auto;}
        .we-develop-box{margin-top: 50px;}
        .we-develop-box.develop-border{margin-top: 0;}
        .schoolbanner{height:auto !important;}
        .products .row {
            flex-direction: column-reverse;
        }
        .products   .banner-content {
            text-align: center;
            margin-top: 25px;
        }
        .products  .banner-image ,  .products .banner-content h1{text-align: center;}
        .banner-image{z-index: 9;}
        .products {
            height: auto;
            
            padding: 100px 0px;
        }
    
        .lr-title p {
            font-size: 14px;
        }
        .lr-title {
            margin-top: 20px;
            text-align: center;
        }
        .cta-read a{justify-content: center;}
        .ctacard{text-align: center;}
    
        .middlebanner {
            padding: 50px 0px;
        }
        .join{flex-direction: column;}
        .feature-tab-head{height: 250px;}
        .mt-15 {
            margin-bottom: 25px;
        }
        .book-demo .auth-bg{display: block; height: auto;}
        .book-demo .bookdemo-image{height: auto;}
        .book-demo .auth-full-page-content {
            background: #f8fcff;
            height: auto;
            max-height: inherit;
        }
        .modal-common{width: 90% !important;}
    
        .bookdemo-point {
            padding: 25px 0px;
            
            gap: 0px;
            flex-direction: column;
        }
        .globlepointsec {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            justify-content: space-evenly;
        }
        .globlepointsec .point-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
        }
        .globlepointsec .point-icon svg{font-size: 12px;}
        .book-demo .bookdemoauth-full-page-content{    padding: 50px 15px 50px 15px;}
        .registernowbtn{display: block;}
     
        .contact-information {
            display: block; margin-bottom: 15px;}
            .copyright p {
                font-size: 15px;
            }
            .contact-end{flex-direction: column;}
        .contact-information h6 {
      
            font-size: 14px;
          }
          .associatedwith {
            margin-top: 0px;
        }
          section.associated-with {
            padding: 50px 15px 20px 15px;  
        }
          .associatedwith .associatedimage img {
            width: 130px;
            margin-right: 0px;
        }
        .trial-content .free-trial {
            position: absolute;
            text-align: left;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            font-size: 12px;
        }
        .section-pedding {
            padding: 30px 0px;
            overflow: hidden;
        }
        h2{font-size: 18px;}
        .section-title p {
            font-size: 12px;
        }
        p {     font-size: 14px; }
        .accordion_meassage_list ul li,.faq_accordion a{font-size: 14px;}
        .feature-tab-info h3{font-size: 16px;}
        .bookdemobtn {
            margin-top: 10px;
        }
        .book-demo .bookdemoauth-bg {
          
            padding: 115px 15px 50px 15px;
        }
        .point-title img{width: 100%;margin-top: 15px;}
        .jobdescription-detail {
            max-height: calc(100vh - 140px);
            padding: 30px 0px;
        }
        input {
            font-size: 12px !important;
        }
          .MuiPaper-elevation{width: 98% !important;}
          .col-buttons a{font-size: 12px;}
          .applicant-status-tab ul li a {
            font-size: 12px;}
            .applicant-status-tab ul{display: flex; margin-bottom: 10px;   justify-content: inherit; flex-wrap: inherit;    overflow: auto;
                padding-bottom: 10px;}
            .applicant-status-tab .nav-tabs .nav-link{white-space: nowrap;    border-top: none !important;  font-size: 12px;    margin-right: 0px;   padding: 10px 15px;}
            .applicant-status-tab ul li a:before, .applicant-status-tab ul li a:after{ display: none;   }
           .ai-details {margin-top: 25px;}
           .webjd {
            
            text-align: center;
        }
      
        .webjd-title h4 { font-size: 14px;}
    .btn {font-size: 12px;}
    .detail-cv {row-gap: 10px;column-gap: 10px;justify-content: center;}
    .user-profile-pick .MuiAvatar-circular {
        
        font-size: 12px;
        width: 30px;
        height: 30px;
    }
    .candiatend h5 {
        font-size: 14px;
    }
    .candidate-score-card {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
    }
    .skill-indicator{padding: 0;}
    .skill-indicator ul{   display: grid; padding: 0;
        grid-template-columns: 1fr 1fr;  gap: 0;  row-gap: 5px;}
        .skill-indicator ul li{    font-size: 10px;}
        .form-skill {
            width: 100%;
            border: none;
           
        }
        .diffrent-skill{flex-direction: column;gap: 0px;}
        .summary-group-wrapper{    overflow: inherit;
            max-height: initial; padding: 0px 0px;}
            .resume_fatch_body {
                padding: 15px 10px;
            }
            .company {
                margin-bottom: 10px;
                padding-left: 0px;
            }
            .candidate-work-head {
                display: flex;
                align-items: inherit;  
                flex-direction: column;
                row-gap: 8px;
            }
            .date-cta{ color: #a6a6a6;    font-weight: 300;   padding: 0px 0px; border: none;}
            .workdate {
                display: grid;
                grid-template-columns: 1fr; 
                row-gap: 10px;
                 
            }
            .company-name span {
                font-size: 15px;
                font-weight: 900;
            }
            .work-head-content{line-height: 20px;}
            .MuiChip-label {font-size: 10px;}
            .duration small {
                position: initial; width: auto;}
                .duration{display: flex; flex-direction: column;}
                .add-ons-flex {
                    display: flex;
                    flex-direction: column;
                }
                .flex1{ margin: 5px 0px;}
                .flex1:after{display: none;}
                .addons-title {
                    padding: 0px 0px 15px 0px;
                    
                }
                .addon-info{line-height: 20px;}
                .menu-bar {position: inherit;}
             .inner-header .navbar-collapse {
                    flex-basis: 100%;
                    flex-grow: 1;
                    align-items: center;
                    position: absolute;
                    top: 77px;
                    width: 100%;
                    background: #fff;
                    left: 0;
                }
                .navbar-toggler{display: block; padding: revert;}
                .col-buttons{gap: 10px;}
                .menu-bar li a {
                    padding-left: 15px !important;
                    padding: 15px 0px;
                    font-size: 14px;
                }
                .menu-bar li {
                    padding: 0px 10px;
                    border-bottom: 1px solid #eee;
                }
                .aboutus .ldcol {
                    background: linear-gradient(180deg,#595da4,rgba(0,211,127,0));
                }
                .our-story:before{display: none;}
                .padding-right {
                    padding-right: 0px;
                    text-align: center;
                    margin-top: 30px;
                }
              .padding-right  .section-title h2{text-align: center;}
              .our-story .banner-image img {
                width: 100%;
            }
            .our-story .banner-image {
                border: 15px solid #ebf6ff;
                border-radius: 19px;
            }
            .our-story .row{flex-direction: column-reverse;}
            .errorlogo{margin-bottom: 20px;}
     
    .message__title{font-size: 36px;margin: 15px 0px;}
    .message__text{font-size: 16px; padding: 0px 15px;    margin-top: 15px;}
    .errorpagebtn {
        margin-top: 15px;}
        .errorpagebtn .btn {
            padding: 10px 25px;
            font-size: 14px;
            text-transform: none;
            margin-top: 10px;
        }
        .errorpage__message.message {
            padding: 0px 14px;
        }
        .message__title2{font-size: 24px; line-height: 35px;}
        .aboutng p {
            font-size: 14px;
            line-height: 27px;
            font-weight: 500;
        }
        .aboutng:after{opacity: 0.9;}
    
        .weare{padding: 25px  15px;}
        .weare p {
            
            font-size: 16px;
          margin-bottom: 0;
            line-height: 30px;
          
        }
        .banner-content-box{flex-direction: column; text-align: center;}
        .upload_banner_content{width: 100%; padding: 30px 0px 30px 0px;}
        .banner-content-box .upload_box{width: 100%;}
        .upload_banner{height: auto; padding-bottom: 30px;}
        .upload_banner_content h1 {
            font-size: 26px;
           margin-bottom: 15px;
            line-height: 42px;
          
        }
        .upload_banner_content p {
            font-size: 18px;
            line-height: 28px;
        margin-bottom: 0px;
        }
        .csv-upload label{padding: 30px 15px; text-align: center;}
        .svg-circle img {
          
            height: 90px;
            
        }
        .csv-upload h5 {
            font-size: 22px;
            line-height: 35px;}
            .csv-upload label p{font-size: 18px !important;}
            .upload_box{width: 95%;}
    }
    
    
    @media only screen and (min-width: 320px) and (max-width: 575px) {
    header{padding: 0px 0px;}
    .offer-cta-content p{height: auto;}
    .bgheight:before{display: none;}
    .develop-border:before {content: "";position: absolute;width: 100%;right: 0;bottom: -36px;background: #ccc;top: inherit;height: 1px;right: 0;left: 0;margin: 0 auto;}
    .develop-border:after{    bottom: -41px;    right: 0;}
    span.and_icon{top: inherit;left: 0;right: 0;margin: 0 auto;bottom: -55px;}
    .section-title{margin-bottom: 20px;}
    .wedevelop-image{padding: 0;}
    .we-develop-box{padding: 20px 0px;}
    .we-develop {margin: 15px 0px;padding: 20px 0px;}
    .our-services{margin: 15px 0px;}
    .big-pera p {padding: 0px 0px;     font-size: 14px;}
    .services-cta{padding: 20px 15px ;}
    .Webapp, .Motiongraphic, .softdevelop, .mobdevelop, .productdesign {margin-top: 15px; height: auto;}
    .technology .nav-tabs .nav-link{font-size: 13px;padding: 0.5rem 5px;}
     
    .technology-cta{    padding: 20px 0 0px 0px;}
    .technology-listing{grid-template-columns: 1fr 1fr 1fr;}
    .offer-image {width: 265px;height: 265px;}
    .weoffer .swiper-button-next {right: 0;}
    .weoffer .swiper-button-prev {left: 0px;}
    .work-cta {padding: 10px 15px;margin-bottom: 15px;}
    .work-circal {width: 190px;  height: 190px;}
    .footer-bottom{gap: 15px;flex-direction: column;}
    .banner-content{text-align: center; margin-top: 25px; padding-right: 0;}
    .weoffer .banner-content h1{text-align: center;font-size: 18px;}
    .bgheight .banner-content h1{text-align: center;font-size: 20px; }
    
    .banner-content h1 br{display: none;}
    .banner-image img {    width: 75%;}
    
    .section-title h2 br{display: none;}
    .swiper-pagination-bullet{    background: #409bdf;}
    .shape1, .shape2, .shape3, .shape4, .shape5, .shape6{display:block;}
    .shape3 img{width: 60px;}
    .shape3{left: inherit; right: 15px;}
    .shape1 {  left: 15px;   }
    .shape2 { left: 43%; top: 13%;  }
    .shape1 img{width: 40px;}
    .shape4 { left: 20px; bottom: 50px;  }
    .shape5 { left: inherit; right: 45px; bottom: 40px; }
    .banner .row {flex-direction: inherit;}
    .banner .row{flex-direction: column-reverse;}
    .offer-image {margin-bottom: 0;padding: 15px 15px;}
    .bubble img {width: 350px;}
    .reverce{flex-direction: column-reverse;}
    .job-image {width: 150px; height: 150px;}
    .career-detail-content {text-align: center; }
    .common-detail{width: 100%;}
    .second-row {flex-direction: column-reverse; }
     .carrer-detail-banner:after{    background-position: center 87%;}
     .mob-none{display: none;}
    
    
    }